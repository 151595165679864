<template>

    <section class="cancellation">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 cancellation__left-col">
                    <h1>{{ cancellation.title1 }}</h1>
                    <div class="details__address">{{ cancellation.workTime }}</div>
                    <div class="details__address">
                        <img src="../../assets/img/phone.png" alt="Phone">
                        {{ cancellation.address1 }} {{ specific.phone }}
                    </div>
                    <div class="details__address">
                        <img src="../../assets/img/email.png" alt="E-mail">
                        {{ specific.email }}
                    </div>
                    <div class="support-text">
                        {{ this.vocab[this.locale].supportText1 }} <a href="#" target="_blank">{{ this.currentURL }}</a>
                        {{ this.vocab[this.locale].supportText2 }}
                        {{ this.vocab[this.locale].supportText3 }} <a :href="specific.parentUrlFull"
                                                                      target="_blank">{{ specific.parentUrl }}</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="cancellation-form">
                        <form id="form-request" @submit.prevent="submitHandler">
                            <div class="cancellation-form__email">{{ cancellation.titleForm1 }}</div>
                            <div class="cancellation-form__row">
                                <input
                                    v-validate="'required|email'"
                                    :class="{'cancellation-form__inp--required': errors.has('Email') }"
                                    v-model="email"
                                    id="email"
                                    name="Email"
                                    class="cancellation-form__inp"
                                    placeholder="Email">
                            </div>

                            <div class="cancellation-form__info">{{ cancellation.info1 }}</div>
                            <div class="cancellation-form__agree">
                                <div class="radio-box">
                                    <label>
                                        <input
                                            v-model="checked"
                                            name="unsubscribe"
                                            class="cancellation-form__checkbox"
                                            id="privacy"
                                            type="checkbox">
                                        <span></span>
                                        {{ cancellation.text1 }}
                                    </label>
                                </div>
                            </div>
                            <button class="cancellation-form__btn" :disabled="!checked">{{ cancellation.titleForm1 }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <Modal v-if="showModal"/>
    </section>

</template>

<script>
import {mapState, mapMutations} from 'vuex';
import Modal from '../Modal/Index'
/* eslint-disable */
export default {
    name: "Unsubscribe",
    components: {
        Modal
    },
    data() {
        return {
            checked: false,
            email: null,
            currentURL: null,
        }
    },
    mounted() {
        this.currentURL = window.location.hostname;
    },
    computed: {
        ...mapState([
            'vocab', 'locale', 'routes', 'showModal', 'errorText', 'specific'
        ]),
        cancellation() {
            return this.vocab[this.locale].cancellation;
        },
    },
    methods: {
        submitHandler() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.axios({
                        method: 'post',
                        url: 'https://msubserv.com/api/unsubscribe',
                        data: {
                            email: this.email.trim(),
                            host: 'act24bill.com'
                        }
                    })
                        .then((response) => {
                            this.email = '';
                            this.setModalText(response.data.message);
                        })
                        .catch(() => {
                            this.setModalText(this.errorText);
                        })
                }
            });
        },
        ...mapMutations([
            'setModalText', 'errorEmailInput', 'clearErrorStatus'
        ])
    }
}
</script>

<style scoped>
</style>
